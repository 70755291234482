.Win {
    width: 60px;
    height: 60px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.8));

    /*bottom: 64%;
    right: 18%;*/

    bottom: 50%;
    left: 18%;

    @media(min-height: 300px) {
        bottom: 64%;
        right: 18%;
        left: inherit;
    }


    @media(min-width: 992px) {
        bottom: 50%;
        right: 28%;
    }

    @media(min-height: 550px) {
        width: 84px;
        height: 84px;
        bottom: 60%;
    }

    &.table_open {
        @media (min-height: 600px) and (max-height: 800px) {
            bottom: 70%;
        }
    }

    &.red {
        background: #D12626;
    }

    &.black {
        background: #000000;
    }

    &.green {
        background: #02791C;
    }

    span {
        font-size: 1.5em;
        color: #E8E8E8;
        font-weight: 700;

        @media(min-height: 550px) {
            font-size: 2.25em;
        }
    }

    .Win__description {
        position: absolute;
        bottom: -20%;
        width: 190%;
        display: flex;
        left: 50%;
        transform: translate(-50%, 0);
        align-items: center;
        justify-content: center;
        background: rgba(25, 25, 23, 0.9);
        border-radius: 5px;
        padding: 0.35em 0;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.8));

        @media(min-width: 992px) {}

        @media(min-width: 992px) {
            bottom: -25%;
        }

        @media(min-width: 1200px) {
            bottom: -35%;
        }

        span {
            font-weight: 700;
            font-size: .875em;
            color: #E8E8E8;

            @media(min-height: 550px) {
                font-size: 1em;
            }
        }
    }
}