@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');


html, body {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  width: 100%;
  font-size: 12px;
  @media(min-width: 992px) {
  	font-size: 14px;
  }
  @media(min-width: 1200px) {
  	font-size: 16px;
  }
}

* {
	border: 0;
	outline: 0;
	border-radius: 0;
}


#root {
	height: 100%
}