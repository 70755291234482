.player-wrapper {
  max-height: 100%;
  width: 100%;
  height: 100%;
  display: grid;
}

.player-inner {
  background: #000;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}
.player-inner video {
	width: 100%;
}

.player-inner .loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.player-inner.loader::after,
.player-inner .loader::before {
  content: '';  
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #FFF;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 1s linear infinite;
}
.player-inner .loader::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}