.Controls {
    user-select: none;
    padding: 1em 0;

    @media(min-height: 550px) {
        padding: 2em 0;
    }



    @media(min-width: 992px) {
    	padding: 2em;
    }

    @media (min-height: 550px) {
        padding: 2em 0;
    }

    

    .chips {
        .buttons {
            button {
                background: none;
                outline: none;
                border-radius: 50px;
                border: 1px solid white;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(255, 255, 255, 0.2);

                &:first-child {
                    margin-right: .625em;
	                @media(min-width: 768px) {
	            		margin-right: .875em;
	            	}
                }
            }
            &.mright {
				margin-right: .875em;
				@media(min-width: 768px) {
	            		margin-right: 1.25em;
	            	}
            }
            &.mleft {
				margin-left: .875em;
				@media(min-width: 768px) {
	            		margin-left: 1.25em;
	            	}       	
            }
        }

        .chip__holder {
            margin-right: 0.675em;

            position: relative;
            @media(min-width: 768px) {
            	margin-right: .875em;
            }
            &:last-child {
                    margin-right: 0;
                }
            .chip {
            	cursor: pointer;
                width: 32px;


            }

            .currentChip {
                position: absolute;
                width: 42px;
                height: 42px;
                border: 2px solid #E8E8E8;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50px;
                display: none;
            }

            &.current {
                .currentChip {
                    display: block;
                }

            }
        }
    }



    .info__block {
        .info {
            color: white;
            font-size: .75em;
            display: flex;
            align-items: center;
            justify-content: start;
            border: 1px solid white;
            border-radius: 50px;
            padding: .25em .75em;
            //width: 110%;
            //width: calc(100% + 2rem);

            span {
            	white-space: nowrap;
            }

            img {
                margin-right: .5em;
            }
        }
    }

    .last__block {
    	overflow: hidden;
    	width: 150px;
        .cell {
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
            margin-right: .25em;
            flex-shrink: 0;

            &.red {
                background: #D12626;
            }

            &.black {
                background: #000000;
            }

            &.green {
                background: #02791C;
            }

            &:last-child {
                margin-right: 0;
            }

            span {
                font-size: .625em;
                color: white;
            }


        }

        .statistic__block {
            .statistic {
                width: 100%;
                height: 2px;
                position: relative;

                .red {
                    height: 2px;
                    background: #D12626;
                }

                .green {
                    height: 2px;
                    background: #02791C;
                }

                .black {
                    height: 2px;
                    background: black;
                }
            }

            span {
                font-size: .625em;
                color: white
            }

            .inscription {
                margin-top: 1em;

                span {
                    color: #D1D1D1;
                }
            }
        }

    }
}