.Timer {
    .time {
        background: rgba(25, 25, 23, 0.7);
        border-radius: 50px;
        display: inline-flex;
        padding: 0.4em 1em;
        height: 36px;
        width: 36px;
        align-items: center;
        justify-content: center;

        span {
            color: white;
        }

        .progress-ring {
            position: absolute;

            .progress-ring__circle {
                stroke-width: 1;
                stroke: #ffffff;
                fill: none;
                transform-origin: center;
                transform: rotate(-90deg);
            }
        }
    }

    .status {
        background: rgba(25, 25, 23, 0.7);
        border-radius: 50px;
        display: inline-flex;
        padding: 0.4em 1em;

        span {
            color: white;
        }
    }

}