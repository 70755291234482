@keyframes openTable {
    from {
        //box-shadow: 0px -1px 0px rgba(255, 255, 255, 0.2);
        //border-radius: 5px 5px 0 0;
    }

    to {
        box-shadow: none;
        border-radius: 0;
    }
}

.Game {
    background: black;
    background-size: cover;
    background-position: center;
    min-height: 100%;
    width: 100%;
    align-content: space-between;
    overflow: hidden;

    .video {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        img {
            width: 100%;
            max-height: 100%
        }

        video {
            width: 100%;
            max-height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .Game__interactive {
        background: rgba(25, 25, 23, 0.7);
        backdrop-filter: blur(2px);

        box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.2);
        border-radius: 5px 5px 0 0;
        &.active {
            animation-name: openTable;
            animation-duration: .2s;
            transition-timing-function: linear;
            animation-fill-mode: forwards;
            animation-delay: .8s;
        }
    }

    .Game__info {
        padding-top: 1em;
        padding-bottom: 1em;

        @media (min-height: 550px) {
            padding-top: 2em;
            padding-bottom: 2em;
        }
    }
    .holder {
        z-index: -10;
    }

    .mobile {
        background: #191917;
        z-index: 3;

        .phone {
            height: 3em;
            width: 6em;
            border: 3px solid #fff;
            border-radius: 10px;
            animation: rotatePhone 1.5s ease-in-out infinite alternate;
        }

        @keyframes rotatePhone {
          0% {
                transform: rotate(0deg)
            }
            50% {
                transform: rotate(-90deg)
            }
            100% {
                transform: rotate(-90deg)
            }
        }

        span {
            font-size: 1.125em;
            color: #fff;
            font-weight: 500;
        }
    }

    .error {
        background: #191917;
        z-index: 5;
        flex-direction: column;
            padding: 0 10em;
        h5 {
            font-weight: 500;
            font-size: 1.125;
            color: #FFFFFF
        }
        p {
            font-weight: 400;
            font-size: .875em;
            line-height: 125%;
            color: #E8E8E8;
        }
        button {
            background: rgba(255, 255, 255, 0.2);
            border: 1px solid #E8E8E8;
            backdrop-filter: blur(49.5px);
            color: #fff;
            border-radius: 999px;
            padding: 0.5em 1em;
        }
    }

    .connect {

        span {
            font-size: 1.125em;
            color: #fff;
            font-weight: 500;
        }

        background: #191917;
        z-index: 4;
        flex-direction: column;

        .loader {
            width: 20px;
            height: 20px;
            border: 1px solid #776E83;
            border-bottom-color: #E9E8EA;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
        }

        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }
}