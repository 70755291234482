.Settings {
    background: rgba(25, 25, 23, 0.7);
    border-radius: 50px;
    display: inline-flex;
    padding: 0.4em 1em;

    .Settings__item {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 1em;

        &:last-child {
            margin-right: 0;
        }
    }

    .select__languages {
        position: absolute;
        top: 3em;
        background: rgba(25, 25, 23, 0.9);
        border-radius: 10px;
        width: 14em;
        right: 0;
        padding: .875em 0.75em;
        display: none;
        z-index: 1;

        &.open {
            display: block;
        }

        .select__heading {
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
        }

        .close__btn {
            padding: 0.25em;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 50px;
            cursor: pointer;
        }

        .item__laguages {
            border-radius: 5px;
            cursor: pointer;
            &:hover {
                background: rgba(255, 255, 255, 0.1)
            }
            span {
                font-weight: 400;
                font-size: .875em;
                color: #E8E8E8;
            }
        }
    }

    .select__sound {
        position: absolute;
        top: 3em;
        background: rgba(25, 25, 23, 0.9);
        border-radius: 10px;
        width: 14em;
        right: 0;
        padding: .875em 0.75em;
        display: none;
        z-index: 1;

        &.open {
            display: block;
        }

        .select__heading {
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
        }

        .close__btn {
            padding: 0.25em;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 50px;
            cursor: pointer;
        }

        .item__sound {
            border-radius: 5px;
            cursor: pointer;
            &:hover {
                background: rgba(255, 255, 255, 0.1)
            }
            span {
                font-weight: 400;
                font-size: .875em;
                color: #E8E8E8;
            }
        }
    }
}