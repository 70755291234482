@keyframes rrr {
    from {
        width: 100%;
        height: auto;
        position: absolute;
        padding: 24em;
        padding-top: 2em;
        padding-bottom: 1em;
        font-size: 9px;
        bottom: 100%;
        right: 10%;
    }

    to {
        position: absolute;
        width: 215px;
        transform: perspective(500px) scaleZ(1) rotateX(30deg);
        font-size: 5px;
        bottom: 110%;
        padding: 1.5em;

        right: 10%;
        margin-bottom: 3em;
    }
}

@keyframes rrr992 {
    from {
        width: 100%;
        height: auto;
        position: absolute;
        font-size: 16px;
        padding: 11em;
        padding-top: 2em;
        padding-bottom: 1em;
        bottom: 100%;
        right: 0%;
    }

    to {
        position: absolute;
        transform: perspective(500px) scaleZ(1) rotateX(30deg);
        padding: 1.5em;
        bottom: 110%;
        width: 340px;
        font-size: 8px;
        right: 5%;
        margin-bottom: 3em;
    }
}

@keyframes bbb992 {
    0% {
        width: 340px;
        transform: perspective(500px) scaleZ(1) rotateX(30deg);
        position: absolute;
        font-size: 5px;
        padding: 1.5em;
        bottom: 110%;
        right: 20%;
        background: rgba(25, 25, 23, 0.7);
        backdrop-filter: blur(2px);
        border-radius: 0px;
    }

    100% {
        position: absolute;
        bottom: 100%;
        right: 0;
        padding: 11em;
        padding-top: 2em;
        padding-bottom: 1em;
        font-size: 16px;
        width: 100%;
        transform: none;
        backdrop-filter: blur(2px);
        background: rgba(25, 25, 23, 0.7);
        border-radius: 0px;
        box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.2);
    }
}

@keyframes bbb {
    0% {
        width: 215px;
        transform: perspective(500px) scaleZ(1) rotateX(30deg);
        position: absolute;
        font-size: 8px;
        padding: 1.5em;
        bottom: 110%;
        right: 10%;
        background: rgba(25, 25, 23, 0.7);
        backdrop-filter: blur(2px);
    }

    100% {
        position: absolute;
        bottom: 100%;
        right: 0;
        padding: 24em;
        padding-top: 2em;
        padding-bottom: 1em;
        font-size: 9px;
        width: 100%;
        transform: none;
        backdrop-filter: blur(2px);
        background: rgba(25, 25, 23, 0.7);
        border-radius: 0px;
        box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.2);
    }
}

@keyframes bbb9922 {
    0% {
        width: 340px;
        transform: perspective(500px) scaleZ(1) rotateX(30deg);
        position: absolute;
        font-size: 5px;
        padding: 1.5em;
        bottom: 110%;
        right: 20%;
        background: rgba(25, 25, 23, 0.7);
        backdrop-filter: blur(2px);
        border-radius: 0px;
    }

    100% {
        position: absolute;
        bottom: 100%;
        right: 0;
        padding: 8em;
        padding-top: 2em;
        padding-bottom: 1em;
        font-size: 16px;
        width: 100%;
        transform: none;
        backdrop-filter: blur(2px);
        background: rgba(25, 25, 23, 0.7);
        border-radius: 0px;
        box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.2);
    }
}

@keyframes bbb756 {
    0% {
        width: 340px;
        transform: perspective(500px) scaleZ(1) rotateX(30deg);
        position: absolute;
        font-size: 5px;
        padding: 1.5em;
        bottom: 110%;
        right: 20%;
        background: rgba(25, 25, 23, 0.7);
        backdrop-filter: blur(2px);
        border-radius: 0px;
    }

    100% {
        position: absolute;
        bottom: 100%;
        right: 0;
        padding: 12em;
        padding-top: 2em;
        padding-bottom: 1em;
        font-size: 13px;
        width: 100%;
        transform: none;
        backdrop-filter: blur(2px);
        background: rgba(25, 25, 23, 0.7);
        border-radius: 0px;
        box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.2);
    }
}



@keyframes openBefore {
    from {
        width: 0;
        left: 0%;
    }

    to {
        width: 100%;
        left: -100%;
    }
}

@keyframes openAfter {
    from {
        width: 0;
        right: 0;
    }

    to {
        width: 100%;
        right: -100%;
    }
}

@keyframes btnOpen {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}




.Table {
    box-sizing: inherit;
    user-select: none;

    &.animationtwo {
        animation-name: bbb;
        animation-duration: 1s;
        transition-timing-function: linear;
        animation-fill-mode: forwards;

        @media(min-width: 992px) {
            animation-name: bbb756;
        }

        @media(min-width: 992px) {
            animation-name: bbb9922;
        }

        &::before {
            content: "";
            width: 100%;
            left: -100%;
            background: rgba(25, 25, 23, 0.7);
            backdrop-filter: blur(2px);
            height: calc(100% + 1px);
            top: -1px;
            position: absolute;
            box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.2);
            animation-name: openBefore;
            animation-duration: 1s;
            transition-timing-function: linear;
            animation-fill-mode: forwards;
        }

        &::after {
            content: "";
            width: 100%;
            right: -100%;
            background: rgba(25, 25, 23, 0.7);
            backdrop-filter: blur(2px);
            height: calc(100% + 1px);
            top: -1px;
            position: absolute;
            box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.2);
            animation-name: openAfter;
            animation-duration: 1s;
            transition-timing-function: linear;
            animation-fill-mode: forwards;
        }

    }

    * {
        box-sizing: inherit;
    }

    .close-btn {
        border: 1px solid #757574;
        border-radius: 50px;
        background: none;
        outline: none;
        right: 4.5em;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        animation-name: btnOpen;
        animation-duration: .2s;
        transition-timing-function: linear;
        animation-fill-mode: forwards;
        animation-delay: .8s;

        @media(min-width: 756px) {
            right: 4.5em;
        }

        @media(min-width: 992px) {
            right: 2em;
        }
    }

    .notify {
        position: absolute;
        top: -1em;
        padding: 0.25em 2em;
        background: #D12626;
        font-size: .875em;
        border-radius: 5px;
        left: 50%;
        transform: translate(-50%, 0);

        span {
            color: #fff;
        }
    }

    &.inactive {
        background: rgba(25, 25, 23, 0.7);
        backdrop-filter: blur(2px);
        border-radius: 5px;
        box-shadow: 1px 1px 0px 0px rgba(255, 255, 255, 0.2), -1px -1px 0px 0px rgba(255, 255, 255, 0.2);

        .close-btn {
            display: none;
        }

        .group__info .info__block {
            width: 10px;
            height: 10px;
        }
    }



    &.animation {
        animation-name: rrr;
        animation-duration: 1s;
        transition-timing-function: linear;
        animation-fill-mode: forwards;

        @media (min-width: 992px), (min-height: 550px) {
            animation-name: rrr992;
        }
    }

    .Table__container {
        display: grid;
        grid-template-columns: .25fr 1fr 1fr 1fr .25fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        gap: 0px 0px;
        grid-template-areas:
            "line1 group1 group4 group7 cell37"
            "line2 group2 group5 group8 cell37"
            "line3 group3 group6 group9 cell37"
            ". group10 group11 group12 ."
            ". group13 group14 group15 .";
    }

    .cell37 {
        grid-area: cell37;
    }

    .group1 {
        grid-area: group1;
    }

    .group4 {
        grid-area: group4;
    }

    .group7 {
        grid-area: group7;
    }

    .group5 {
        grid-area: group5;
    }

    .group8 {
        grid-area: group8;
    }

    .group3 {
        grid-area: group3;
    }

    .group6 {
        grid-area: group6;
    }

    .group9 {
        grid-area: group9;
    }

    .line2 {
        grid-area: line2;
    }

    .line3 {
        grid-area: line3;
    }

    .group10 {
        grid-area: group10;
    }

    .group11 {
        grid-area: group11;
    }

    .group12 {
        grid-area: group12;
    }

    .line1 {
        grid-area: line1;
    }

    .group2 {
        grid-area: group2;
    }

    .group13 {
        grid-area: group13;
    }

    .group14 {
        grid-area: group14;
    }

    .group15 {
        grid-area: group15;
    }

    .clickArea {
        cursor: pointer;
        position: relative;

        .chip {
            position: absolute;
            left: 25%;
            width: 50%;
            z-index: 1;

            &.top {
                top: -25%;
            }

            &.bottom {
                bottom: -25%;
            }

            &.right,
            &.left {
                right: -25%;
                left: auto;
            }
        }

        &.cAmini {
            .chip {
                width: auto;
                height: 50%;
            }

            &--w2 .chip {
                left: 37.5%;
            }

            &--w4 .chip {
                left: 44%;
            }
        }
    }

    .Cell {
        width: 30%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid #FFFFFF;
        border-bottom: 1px solid #FFFFFF;
        margin: 0;

        span {
            color: white;
        }

        &.red {
            background: #D12626;
        }

        &.black {
            background: #000000;
        }

        &:before {
            content: "";
            padding-top: 100%;
            float: left;
        }

        &.num {
            &:after {
                content: "";
                width: 100%;
                position: absolute;
                height: 100%;
                border: 3px solid yellow;
            }
        }


    }

    .group1,
    .group4,
    .group7 {
        cursor: pointer;

        .Cell {
            border-top: 1px solid #FFFFFF;

            span {
                color: white;
            }
        }
    }

    .group7,
    .group8,
    .group9 {
        cursor: pointer;

        .Cell:nth-child(4) {
            border-right: 1px solid #FFFFFF;
        }

        span {
            color: white;
        }
    }

    .group13,
    .group14,
    .group15 {
        cursor: pointer;
        border: 1px solid #FFFFFF;

        span {
            color: white;
        }

        .group__info:last-child {
            border-left: 1px solid #FFFFFF;
        }
    }

    .group13,
    .group14 {
        border-right: none;
    }

    .group13 {
        border-radius: 0 0 0 5px;
    }

    .group15 {
        border-radius: 0 0 5px 0;
    }

    .line1,
    .line2,
    .line3 {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #FFFFFF;
        border-right: none;

        span {
            color: white;
            white-space: nowrap;
        }

        &:nth-child(n+2) {
            border-top: none;
        }
    }

    .line1 {
        border-radius: 5px 0 0 0;
    }

    .line3 {
        border-radius: 0 0 0 5px;
    }

    .group10,
    .group11,
    .group12 {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #FFFFFF;
        border-left: 1px solid #FFFFFF;


        span {
            color: white;
        }
    }

    .group10,
    .group11 {
        border-right: none;
    }

    .group__info {
        display: flex;
        align-items: center;
        justify-content: center;


        .info__block {
            width: 1.25em;
            height: 1.25em;
            border-radius: 2px;
            border: 1px solid white;
            transform: rotate(45deg);

            &.red {
                background: #D12626;
            }

            &.black {
                background: #000000;
            }
        }

    }

    .cell37 {
        width: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #02791C;
        border: 1px solid #FFFFFF;
        border-left: none;
        border-radius: 0 5px 5px 0;

        &.num {
            &:after {
                content: "";
                width: 100%;
                position: absolute;
                height: 100%;
                border: 3px solid yellow;
                border-radius: 0 5px 5px 0;
            }
        }

        span {
            color: white;
        }
    }
}