.DescriptionModal__Overlay {
    position: fixed;
    inset: 0px;
    background: rgba(26, 26, 26, 0.8);
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;


    .DescriptionModal__Content {
        .DescriptionModal__Holder {
            height: calc(100% - 3.5rem);
        }

        .DescriptionModal {
            inset: 0px;
            border: none;
            background: #191917;
            outline: none;
            max-height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 1.5em;
            border-radius: 10px;

        }

        .modal__content_holder {
            overflow-y: auto;
            position: relative;
            flex: 1 1 auto;
            scrollbar-color: #23262F transparent;
            scrollbar-width: thin;

            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-track {
                background-color: rgba(100, 102, 113, 0.2);
                ;
                border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(100, 102, 113, 0.2);
                border-radius: 5px;
            }
        }

        .holder_close-modal-button {
            position: absolute;

            border: 0;
            outline: 0;
            right: 1em;
            top: 1em;
            display: flex;
            justify-content: center;

        }

        .close-modal-button {
            background: #3C3D3D;
            padding: 0.25em;
            border-radius: 50px;
            z-index: 1;
            display: flex;
            align-items: center;

            font-weight: 500;
            font-size: .875em;
            color: #E7E7E8;
            border: none;

            &:hover {
                background: rgba(255, 255, 255, 0.3);
                cursor: pointer;
            }

        }

        .DescriptionModal__Header {
            h2 {
                font-weight: 500;
                font-size: 1.125em;
                color: #FFFFFF;
            }

        }

        .DescriptionModal__Body {
            h4 {
               font-weight: 500;
font-size: 1em;
color: #E8E8E8;
                margin-bottom: .75em;
                padding-top: .75em;
            }

            p {
                font-weight: 400;
				font-size: .875em;
				line-height: 125%;
				color: #E8E8E8;
            }

            ul {
                font-weight: 400;
				font-size: .875em;
				line-height: 125%;
				color: #E8E8E8;
                margin-bottom: .75em;
            }
        }
    }
}